export const TRANSACTION_SAMPLE = `{
    "hash": "0x86540e14cfa1f13a98fadbbf64cd2dbed3763453d53e05f737c664f309b4325e",
    "feeDelegate": true,
    "unsignedTx": {
        "type": "0x2",
        "chainId": "0x458",
        "nonce": "0x2f",
        "to": "0x4313833c79f046ca63b1b73db64234dd5f47bd8f",
        "gas": "0x4d604",
        "gasPrice": null,
        "maxPriorityFeePerGas": "0x174876e800",
        "maxFeePerGas": "0x174876e802",
        "value": "0x0",
        "input": "0xc0f0a96d6e6967687463726f770000000000000000000000000000000000000000000000446f6c6c617243524f57000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000008ac7230489e8000065786368616e6765546f546f6b656e00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000120000000000000000000000000000000000000000000000000000000000000045800000000000000000000000000000000000000000000000017970e289ff8199e00000000000000000000000000000000000000000000000000000000027daedc00000000000000000000000000000000000000000000000000000000000001a00000000000000000000000000000000000000000000000000000000000000041a9216adc338e8cb0c92a0656fb1dc2f78e8034bb267bbd208693b4852f1232e44fb397e18f86ebda1b4df448e100a8385194c433931cfd5174b689bbf432d31a1b00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000004190f81850191444f20a02f856e96b048090113df0bc47127b18fa6284df489f1562be29afbd04f490282f350ca660556e4df6552b796dc388041c20bdd9ca74f11b00000000000000000000000000000000000000000000000000000000000000",
        "accessList": [],
        "v": "0x1",
        "r": "0x1",
        "s": "0x1",
        "yParity": "0x1",
        "hash": "0x4336a89ea1e0a8a359bd176988d288e1dd15fa9fa19c771ec753fa68601ee8df"
    }
}`;

export const MESSAGE_SAMPLE =
  "0x8f54f1c2d0eb5771cd5bf67a6689fcd6eed9444d91a39e5ef32a9b4ae5ca14ff";

export const PLAY_APP_MESSAGE_SAMPLE = `[
  {"type": "address", "value": "0x7d28fcfd821be2977a3fa857c5e71011f587d8f1", "name": "contract"},
  {"type": "address", "value": "0xb20ccf56354157e75671891a568162881bbdf1a9", "name": "user"},
  {"type": "uint256", "value": "0", "name": "nonce"},
  {"type": "bytes32", "value": "0x6561726e20746f6b656e00000000000000000000000000000000000000000000", "name": "column"},
  {"type": "uint256", "value": "300000000000000000000", "name": "amount"}
]`;

// chainName 제거 및 별도 input 값으로 분리. jrlim on 2024.09.20
// "chainName": "tornado",
export const PLAY_APP_TRANSACTION_SAMPLE = `{
    "hash": "0x9556b67355c5ee6e551edf51fdb6d1efe622b01f20091e386724c1082726462a",
    "unsignedTx": {
        "typeInt": 49,
        "type": "TxTypeFeeDelegatedSmartContractExecution",
        "nonce": "0x1",
        "gasPrice": "0x5d21dba00",
        "gas": "0x5f5e100",
        "to": "0x86d0a4c1ddae2971d95661a8e65522bbd523574e",
        "value": "0x0",
        "from": "0x3690001a4883a044a7af78139c61190db4562647",
        "input": "0x095ea7b300000000000000000000000086d0a4c1ddae2971d95661a8e65522bbd523574e00000000000000000000000000000000000000000000000ad78ebc5ac6200000",
        "signatures": [
            {
                "V": "0x10000000000000000",
                "R": "0x10000000000000000",
                "S": "0x1"
            }
        ],
        "feePayer": "0x454b8b413ab709e44827dcd8aeb172de16517a4b",
        "feePayerSignatures": [],
        "hash": "0x2e4e666bb22bbbf2413b2531a5d616b3f9a779c3358512bc3d1ad1ece34cc5e6"
    }
}`;
