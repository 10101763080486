import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import {
  MESSAGE_SAMPLE,
  PLAY_APP_MESSAGE_SAMPLE,
  PLAY_APP_TRANSACTION_SAMPLE,
  TRANSACTION_SAMPLE,
} from "./data/sampleData";

const environments = {
  local: {
    clientId: "1143-zxfrebsyb0dg09r3.apps.wemixnetwork.com",
    baseUrl: "http://localhost:4023",
  },
  alpha: {
    clientId: "1143-zxfrebsyb0dg09r3.apps.wemixnetwork.com",
    baseUrl: "https://alpha-sdk.wemix.co",
  },
  dev: {
    clientId: "1143-zxfrebsyb0dg09r3.apps.wemixnetwork.com",
    baseUrl: "https://dev-sdk.wemixnetwork.com",
  },
  stage: {
    clientId: "1143-zxfrebsyb0dg09r3.apps.wemixnetwork.com",
    baseUrl: "https://stg-sdk.wemixnetwork.com",
  },
  prod: {
    clientId: "1143-zxfrebsyb0dg09r3.apps.wemixnetwork.com",
    baseUrl: "https://sdk.wemixnetwork.com",
  },
};

type Environment = keyof typeof environments;

const initialWallets = {
  unawallet: true,
  wemixwallet: true,
  playwallet: true,
  playwalletwc: false,
  wemixplayapp: true,
};

function App() {
  const [environment, setEnvironment] = useState<Environment>("alpha");
  const [chainId, setChainId] = useState(1112);
  const [chainName, setChainName] = useState<string>("tornado");
  const [message, setMessage] = useState(MESSAGE_SAMPLE);
  const [transaction, setTransaction] = useState(TRANSACTION_SAMPLE);
  const [playMessage, setPlayMessage] = useState<string>(
    PLAY_APP_MESSAGE_SAMPLE
  );
  const [playTransaction, setPlayTransaction] = useState<string>(
    PLAY_APP_TRANSACTION_SAMPLE
  );
  const [result, setResult] = useState("");
  const [wemixInstance, setWemixInstance] = useState<IWemixInstance>();
  const [wallets, setWallets] = useState<any>(initialWallets);
  const { unawallet, wemixwallet, playwallet, wemixplayapp } = wallets;

  const handleChangeEnvironment = (event: ChangeEvent<HTMLInputElement>) => {
    setEnvironment((event.target as HTMLInputElement).value as Environment);
  };

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWallets({
      ...wallets,
      [event.target.name]: event.target.checked,
    });
  };

  const initialize = () => {
    const supportedWallet = Object.keys(wallets).filter(
      (key) => wallets[key]
    ) as SupportedWalletType[];
    window.Wemix.initialize({
      ...environments[environment],
      supportedWallet,
      log: "debug",
    })
      .then((res) => {
        setWemixInstance(res);
      })
      .catch((err) => {
        setResult(err);
      });
  };

  const connect = async () => {
    wemixInstance
      ?.connect(chainId)
      .then((res) => {
        setResult(JSON.stringify(res, null, 4));
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const forcedConnect = async () => {
    wemixInstance
      ?.forcedConnect(chainId)
      .then((res) => {
        setResult(JSON.stringify(res, null, 4));
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const disconnect = () => {
    wemixInstance
      ?.disconnect()
      .then(() => {
        setResult("successfully disconnected.");
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const signMessage = async (data: string) => {
    wemixInstance
      ?.signMessage(data)
      .then((res) => {
        setResult(JSON.stringify(res, null, 4));
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const signTransaction = async (data: string) => {
    wemixInstance
      ?.signTransaction(data)
      .then((res) => {
        setResult(JSON.stringify(res, null, 4));
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const playSignMessage = async (data: string) => {
    wemixInstance
      ?.playSignMessage(data)
      .then((res) => {
        setResult(JSON.stringify(res, null, 4));
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const playSignTransaction = async (data: string, chainName: string) => {
    wemixInstance
      ?.playSignTransaction(data, chainName)
      .then((res) => {
        setResult(JSON.stringify(res, null, 4));
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const switchChain = async (chainId: number) => {
    wemixInstance
      ?.switchChain(chainId)
      .then(() => {
        setResult("successfully switched chain.");
      })
      .catch((err) => {
        setResult(JSON.stringify(err, null, 4));
      });
  };

  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("WEMIXSDK_THEME") || "light"
  );
  const [currentLanguage, setCurrentLanguage] = useState("");

  useEffect(() => {
    if (!wemixInstance) return;
    setCurrentLanguage(localStorage.getItem("WEMIXSDK_LANG") || "en");
  }, [wemixInstance]);

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <AppBar>
        <Toolbar
          sx={{
            maxWidth: 1200,
            m: "0 auto",
            width: "100%",
            p: "0 24px",
            display: "flex",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h5">JS SDK SAMPLE</Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ my: 10 }}>
        {/* 초기화 */}
        <Box>
          <Stack>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Wallets to Enable</FormLabel>
              <FormGroup sx={{ flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={unawallet}
                      onChange={handleCheckBox}
                      name="unawallet"
                    />
                  }
                  label="unawallet"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wemixwallet}
                      onChange={handleCheckBox}
                      name="wemixwallet"
                    />
                  }
                  label="wemixwallet"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={playwallet}
                      onChange={handleCheckBox}
                      name="playwallet"
                    />
                  }
                  label="playwallet"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={playwalletwc}
                      onChange={handleCheckBox}
                      name="playwalletwc"
                    />
                  }
                  label="playwalletwc"
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wemixplayapp}
                      onChange={handleCheckBox}
                      name="wemixplayapp"
                    />
                  }
                  label="wemix play app"
                />
                {/*<FormControlLabel*/}
                {/*  control={*/}
                {/*    <Checkbox*/}
                {/*      checked={metamask}*/}
                {/*      onChange={handleCheckBox}*/}
                {/*      name="metamask"*/}
                {/*    />*/}
                {/*  }*/}
                {/*  label="metamask"*/}
                {/*/>*/}
              </FormGroup>
            </FormControl>
          </Stack>
          <Stack>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Environment
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={environment}
                onChange={handleChangeEnvironment}
                sx={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value="alpha"
                  control={<Radio />}
                  label="Alpha"
                />
                <FormControlLabel value="dev" control={<Radio />} label="Dev" />
                <FormControlLabel
                  value="stage"
                  control={<Radio />}
                  label="Stage"
                />
                <FormControlLabel
                  value="prod"
                  control={<Radio />}
                  label="Prod"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              initialize();
            }}
            disabled={window.Wemix.isInitialized}
          >
            Initialize
          </Button>
          <Typography mt={1}>
            isInitialized: {window.Wemix.isInitialized ? "true" : "false"}
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* 연결 */}
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TextField
            id="chainId"
            label="chainId"
            variant="outlined"
            value={chainId}
            type="number"
            onChange={(e) => {
              setChainId(Number(e.target.value));
            }}
            sx={{ width: 150 }}
          />
          {wemixInstance && (
            <>
              <FormControl sx={{ width: 150 }}>
                <InputLabel id="select-language">language</InputLabel>
                <Select
                  id="select-language"
                  label="language"
                  value={currentLanguage}
                  onChange={(e) => {
                    setCurrentLanguage(e.target.value);
                    wemixInstance?.setLanguage(
                      e.target.value as WemixLanguageType
                    );
                  }}
                >
                  <MenuItem value="ko">ko</MenuItem>
                  <MenuItem value="en">en</MenuItem>
                  <MenuItem value="zh-Hans">zh-Hans</MenuItem>
                  <MenuItem value="zh-Hant">zh-Hant</MenuItem>
                  <MenuItem value="ja">ja</MenuItem>
                  <MenuItem value="th">th</MenuItem>
                  <MenuItem value="es">es</MenuItem>
                  <MenuItem value="pt">pt</MenuItem>
                  <MenuItem value="ru">ru</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: 150 }}>
                <InputLabel id="select-theme">theme</InputLabel>
                <Select
                  id="select-theme"
                  label="theme"
                  value={currentTheme}
                  onChange={(e) => {
                    setCurrentTheme(e.target.value);
                    wemixInstance?.setTheme(e.target.value as WemixThemeType);
                  }}
                >
                  <MenuItem value="light">Light</MenuItem>
                  <MenuItem value="dark">Dark</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              connect();
            }}
          >
            Connect
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              disconnect();
            }}
          >
            Disconnect
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              forcedConnect();
            }}
          >
            Forced Connect
          </Button>
        </Box>
        <Typography mt={1}>
          isConnected: {wemixInstance?.isConnected ? "true" : "false"}
        </Typography>
        <Typography>
          connectedWallet: {wemixInstance?.connectedWallet}
        </Typography>
        <Typography>address: {wemixInstance?.address}</Typography>
        <Typography>chainId: {wemixInstance?.chainId}</Typography>

        <Divider sx={{ my: 3 }} />

        {/* 체인 변경 */}
        <Box>
          <TextField
            id="chainId"
            label="chainId"
            variant="outlined"
            value={chainId}
            type="number"
            onChange={(e) => {
              setChainId(Number(e.target.value));
            }}
            sx={{ width: 150 }}
          />
        </Box>
        <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              switchChain(chainId);
            }}
          >
            switch chain
          </Button>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* 메시지 서명 */}
        <Box>
          <TextField
            id="message"
            label="message to sign"
            variant="outlined"
            fullWidth
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              signMessage(message);
            }}
          >
            sign message
          </Button>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* 트랜잭션 서명 */}
        <Box>
          <TextField
            id="transaction"
            label="transaction to sign"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={transaction}
            onChange={(e) => {
              setTransaction(e.target.value);
            }}
          />
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              signTransaction(transaction);
            }}
          >
            sign Transaction
          </Button>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Wemix Play App Sign Message (play_eth_sign) */}
        <Box>
          <TextField
            id="play-sign-message"
            label="message to sign for wemix play app"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={playMessage}
            onChange={(e) => {
              setPlayMessage(e.target.value as string);
            }}
          />
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              playSignMessage(playMessage);
            }}
          >
            play sign message
          </Button>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Wemix Play App Sign Transaction (play_eth_sign) */}
        <Box>
          <TextField
            id="chainName"
            label="chainName"
            variant="outlined"
            value={chainName}
            type="string"
            onChange={(e) => {
              setChainName(e.target.value as string);
            }}
            sx={{ width: 150, mb: 1 }}
          />
          <TextField
            id="play-sign-transaction"
            label="transaction to sign for wemix play app"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={playTransaction}
            onChange={(e) => {
              setPlayTransaction(e.target.value as string);
            }}
          />
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              playSignTransaction(playTransaction, chainName);
            }}
          >
            play sign transaction
          </Button>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* 응답 확인 */}
        <Box sx={{ overflowWrap: "break-word" }}>
          <Typography variant="h6">Result</Typography>
          <Typography
            paragraph
            style={{
              overflowWrap: "break-word",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordBreak: "break-all",
            }}
          >
            {result}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default App;
